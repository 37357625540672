import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Section, Box, Text } from "../../components/Core";
import bgHeroPattern from "../../assets/image/png/fond-rose-vert.png";

const BoxStyled = styled(Box)`
  @media (min-width: 768px) {
    padding-left: 1rem;
  }
`

const SectionStyled = styled(Section)`
  @media (min-width: 768px) {
    height: 100vh;
  }
`;

const HeroImage = styled.img`
  width: 60%;
  position: absolute;
  top: -20%;
  right: -5%;
  z-index: -999;

  @media (max-width: 768px) {
    width: 100%;
    position: relative;
    top: auto;
    right: auto;
  }
`;

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <SectionStyled hero className="position-relative">
        <Container fluid style={{marginTop: '10%'}}>
          <Row>
            <Col lg="11" className="mb-5 mb-lg-0">
              <BoxStyled>
                {/*<Text
                  variant="tag"
                  mb={2}
                  className="text-uppercase"
                  color="white"
                  mb="20px"
                >
                  👋 FROM MONTREAL
                </Text>*/}
                <Title>
                  On concrétise <br />
                  vos meilleures idées.
                </Title>
              </BoxStyled>
            </Col>
          </Row>
          <HeroImage src={bgHeroPattern} />
        </Container>
      </SectionStyled>
    </>
  );
};

export default Hero;
